import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Checkbox } from 'primereact/checkbox';
import { FileUpload } from 'primereact/fileupload';
import { Toast } from 'primereact/toast';
import ErrorModal from '../../newUi/ErrorModal';
import SuccessModal from '../../newUi/SuccessModal';
import LocalstorageService from '../../services/localstorage.service';
import FullPageLoader from '../FullPageLoader';
import { getURL, uploadStatementsIMG, uploadStatements, publishStatements, eachStatementsUploads } from '../../services/banking.service';
import { TriggerJuloEvent } from '../../services/appscript.service';
import { Dialog } from 'primereact/dialog';
import "./banking.component.css"
import { SpeedDial } from 'primereact/speeddial';
import { verifySession } from '../../services/verify.service';
import BrandBadge from '../../Widget/BrandBadge/brandBadge.widget';
import { Accordion, AccordionTab } from 'primereact/accordion';
import juloImg from '../../assets/images/julo.png';

const MultiBanking = ({ back, onFormSuccess, isTranslated, body, clientJulo }) => {
    const toast = useRef(null);
    const { t } = useTranslation();
    const fileUploadRef = useRef(null);
    const [isMobileScreen, setIsMobileScreen] = useState(false);
    const [isDataLoading, setIsDataLoading] = useState(false);
    const [selectedUploadType, setSelectedUploadType] = useState(null);
    const [files, setFiles] = useState([]);
    // const [templateURL, setTemplateURL] = useState('');
    // const [uploadURL, setUploadURL] = useState('');
    const [password, setPassword] = useState('');
    const [isFilesSelected, setIsFilesSelected] = useState(false);
    const [isDialogVisible, setIsDialogVisible] = useState(false);
    const [falseStatements, setFalseStatements] = useState([]);
    const [allBanksNamed, setAllBanksNamed] = useState(false);
    // const [imageStatements, setImageStatements] = useState([]);
    const [mode, setMode] = useState(0);
    const [isAllowSubmit, setAllowSubmit] = useState(false);
    const [selectedBank, setSelectedBank] = useState(null);
    const [showSuccessDialog, setShowSuccessDialog] = useState(false);
    // const [sampleIndex, setSampleIndex] = useState(null);
    const [machineTrigger, setMachineTrigger] = useState(false);
    const [isHITLEnabled, setIsHITLEnabled] = useState(false);

    const localstorageService = new LocalstorageService();
    const products_list = localstorageService.getItem('product_list');

    const uploadOptions = [
        { option: 'PDF (For Uploading Single / Multiple Bank Statements)', show: 'PDF' },
        { option: 'Image (For Uploading Single Bank Statement)', show: 'Image' }
    ]

    const banksWithHITL = [
        { option: 'BCA', name: 'BCA' },
        { option: 'BRI', name: 'BRI' },
        { option: 'Mandiri', name: 'MANDIRI' },
        { option: 'BNI', name: 'BNI' },
        { option: 'BNI_DIGIC', name: 'BNI DIGIC' },
        { option: 'OCBC', name: 'OCBC (BETA)' },
        { option: 'CIMB', name: 'CIMB' },
        { option: 'Permata', name: 'PERMATA' },
        { option: 'Danamon', name: 'DANAMON' },
        { option: 'BTN', name: 'BTN' },
        { option: 'BJB', name: 'BJB' },
        { option: 'Others', name: 'Others' }
    ]

    const banksWithNoHITL = [
        { option: 'BCA', name: 'BCA' },
        { option: 'BRI', name: 'BRI' },
        { option: 'Mandiri', name: 'MANDIRI' },
        { option: 'BNI', name: 'BNI' },
        { option: 'BNI_DIGIC', name: 'BNI DIGIC' },
        { option: 'OCBC', name: 'OCBC (BETA)' },
        { option: 'CIMB', name: 'CIMB' },
        { option: 'Permata', name: 'PERMATA' },
        { option: 'Danamon', name: 'DANAMON' },
        { option: 'BTN', name: 'BTN' },
        { option: 'BJB', name: 'BJB' },
        { option: 'Others', name: 'Others' }
    ]

    // #region Notification Handlers
    const showSuccess = (message) => {
        if (!clientJulo) {
            toast.current.show({ severity: 'success', summary: 'Success', detail: message, life: 3000 });
        }
    }
    const showError = (message) => {
        toast.current.show({ severity: 'error', summary: 'Error', detail: message, life: 6000 });
    }
    const showWarn = (message) => {
        toast.current.show({ severity: 'warn', summary: message, life: 3000 });
    }
    // #endregion Notification Handlers

    useEffect(async () => {
        if (products_list.includes('HITL')) {
            setIsHITLEnabled(true);
        }

        if (clientJulo && selectedUploadType?.show == "PDF") {
            await TriggerJuloEvent(3);
        } else if (clientJulo && selectedUploadType?.show == "Image") {
            await TriggerJuloEvent(4);
        }
    }, [selectedUploadType]);

    useEffect(async () => {
        if (clientJulo && selectedBank) {
            await TriggerJuloEvent(10, selectedBank.option);
        }
    }, [selectedBank]);

    useEffect(async () => {
        if (clientJulo && isDialogVisible) {
            await TriggerJuloEvent(10);
        }
    }, [isDialogVisible]);

    const handleInputBlur = async () => {
        if (clientJulo && password.trim() !== '') {
            await TriggerJuloEvent(8);
        }
    };

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 577) {
                setIsMobileScreen(true);
            } else {
                setIsMobileScreen(false);
            }
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const customUploadDocuments = async (e) => {
        const verification = await verifySession();
        if (verification) {
            if (verification.status == "failed") {
                window.location.reload();
                return
            }
        }
        setIsDataLoading(true)

        const localStorageService = new LocalstorageService();
        const bank = selectedBank ? selectedBank.option : '';

        if (selectedUploadType && selectedUploadType.show == "PDF") {
            let response = await uploadStatements(localStorageService.getItem('id', false, 'tokenVerification'), bank.toLowerCase(), e.files, false, password)
            console.log(response)
            if (response.status == 200) {
                if (response?.falseStatements?.length > 0) {
                    setFiles(e.files);

                    for (let i = 0; i < response?.falseStatements?.length; i++) {
                        let temp = falseStatements;
                        temp.push({ id: i, name: response?.falseStatements[i], bank: '' });
                        setFalseStatements(temp);
                    }

                    setIsDialogVisible(true)
                    setIsDataLoading(false)
                } else {
                    setMachineTrigger(true);
                    fileUploadRef.current.clear();
                    setMachineTrigger(false);
                    setIsDataLoading(false)
                    setAllowSubmit(true)
                    showSuccess('Statements Uploaded Successfully');
                    onFormSuccess(true);
                    if (clientJulo) { setShowSuccessDialog(true); await TriggerJuloEvent(6); }
                    setSelectedUploadType(null);
                }
            }else if (response.status == 400 && response.isPasswordProtected == true){
                setIsDataLoading(false)
                showWarn("PDF is Password Protected")
            }
            else {
                setIsDataLoading(false)
                showError("Network Issue")
            }


        } else {
            setIsDataLoading(true)
            let id = localStorageService.getItem('id', false, 'tokenVerification')
            await uploadStatementsIMG(id, bank.toLowerCase(), e.files)
            await uploadStatements(id, bank.toLowerCase(), [e.files[0]], true);
            setMachineTrigger(true);
            fileUploadRef.current.clear();
            setMachineTrigger(false);
            setIsDataLoading(false)
            setAllowSubmit(true)
            showSuccess('Statements Uploaded Successfully');
            setIsDataLoading(false)
            onFormSuccess(true);
            setSelectedUploadType(null);
            if (clientJulo) { setShowSuccessDialog(true); await TriggerJuloEvent(6); }
        }
    }

    const selectedFileTemplate = (option, props) => {
        if (option) {
            return (
                <div className="p-clearfix" style={{
                    alignItems: 'center',
                    display: 'flex'
                }}>
                    <i className={option.show == "PDF" ? "pi pi-file-pdf" : "pi pi-image"}

                        style={{ fontSize: '18px', float: 'left', marginRight: '1rem' }}></i>

                    <span style={{ float: 'left' }}>{option.show}</span>
                </div>
            );
        }

        return <span>{props.placeholder}</span>;
    };

    const clean = async () => {
        setFiles([]);
        setFalseStatements([]);
        setIsDialogVisible(false);
        setAllowSubmit(false);
        setIsFilesSelected(false);
        // setSelectedUploadType(null);
        setSelectedBank(null);
        setPassword('');

    }

    const handleNamingBanks = async () => {
        setIsDataLoading(true);
        let statementNames = falseStatements.map(statement => statement.name);
        let matchingFiles = files.filter(file => statementNames.includes(file.name));
        let banks_temp = falseStatements.map(statement => statement.bank.toLowerCase());
        const localStorageService = new LocalstorageService();
        await eachStatementsUploads(localStorageService.getItem('id', false, 'tokenVerification'), banks_temp, matchingFiles, false, password);
        setIsDialogVisible(false);
        setIsDataLoading(false);
        setFiles([]);
        setFalseStatements([]);
        setMachineTrigger(true);
        fileUploadRef.current.clear();
        setMachineTrigger(false);
        onFormSuccess(true);
        setSelectedUploadType(null);
        if (clientJulo) { setShowSuccessDialog(true); await TriggerJuloEvent(6); }
    };

    const observeAllBanksNamed = () => {
        if (falseStatements.every((statement) => statement.bank != '')) {
            setAllBanksNamed(true)
        }
    }

    const ModelHeaderContent = (
        <div style={{ flex: '1', textAlign: 'center' }}>
            {t('selectPartnerPage.Banking.unRecognisedStatement.title')}
        </div>
    )

    const ModelFooterContent = (
        <div style={{ textAlign: 'center', width: '100%' }}>
            <button type="submit" className="btn nav-btn-pwc next-btn btn-n btn-next-partner-n"
                onClick={handleNamingBanks}
                disabled={allBanksNamed ? false : true}
            >{t('form.submit')}
            </button>
        </div>
    )

    const JuloModelFooterContent = (
        <div style={{ textAlign: 'right', width: '100%' }}>
            <button type="submit" className="btn nav-btn-pwc next-btn btn-n btn-next-partner-n julo-btn"
                onClick={async () => {
                    setShowSuccessDialog(false);
                    setIsDataLoading(true);
                    await TriggerJuloEvent(11);
                    window.location.href = 'https://www.julo.co.id/blog/data-kamu-aman-dengan-JULO?in_app=true';
                }}
            >{t('selectPartnerPage.Banking.modal.continue')}
            </button>
        </div>
    )

    const [tempFiles, setTempFiles] = useState(0);

    const onSelectFUHandler = async (e) => {
        {
            if (clientJulo) {
                setTimeout(() => {
                    setTempFiles(e.files.length);
                    const uploadButton = document.querySelector('.p-fileupload-buttonbar button:nth-of-type(1)');
                    if (e.files.length >= 3) {
                        uploadButton.removeAttribute('disabled');
                    } else {
                        uploadButton.setAttribute('disabled', 'disabled');
                    }
                }, 100);
            }

            if (selectedUploadType && selectedUploadType.show == "PDF") {
                for (let i = 0; i < e.files.length; i++) {
                    if (!e.files[i].type.match('pdf')) {
                        e.files.splice(i, 1);
                    }
                }

                if (e.files.length > 30) {
                    let excess = e.files.length - 30
                    showWarn('Max file limit is 30 Statements')

                    for (let i = 0; i < excess; i++) {
                        e.files.pop()
                    }
                }
            }
            if (selectedUploadType && selectedUploadType.show == "Image") {
                for (let i = 0; i < e.files.length; i++) {
                    if (!e.files[i].type.match('image/*')) {
                        e.files.splice(i, 1);
                    }
                }

                if (e.files.length > 100) {
                    let excess = e.files.length - 100
                    showWarn('Max file limit is 100 Images')

                    for (let i = 0; i < excess; i++) {
                        e.files.pop()
                    }
                }
            }
        }
    }

    const onRemoveFUHandler = async (e) => {
        if (clientJulo) {
            await TriggerJuloEvent(13);
            const t = tempFiles - 1;
            setTempFiles(t);
            setTimeout(() => {
                const uploadButton = document.querySelector('.p-fileupload-buttonbar button:nth-of-type(1)');
                if (t >= 3) {
                    uploadButton.removeAttribute('disabled');
                } else {
                    uploadButton.setAttribute('disabled', 'disabled');
                }
            }, 100);
        }
    }

    const onClearFUHandler = async (e) => {
        setIsFilesSelected(false); setTempFiles(0);
        if (clientJulo && !machineTrigger) {
            await TriggerJuloEvent(7);
        }
    }

    const onBeforeUploadFUHandler = async (e) => {
        setIsDataLoading(true)
    }

    const emptyTemplateFUHandler = (e) => {
        setIsFilesSelected(false)
        setTempFiles(0);
        if (isMobileScreen) {
            const element = document.getElementsByClassName('p-fileupload-content')[0];
            if (element) {
                element.style.display = 'none';
            }
        }
        return (<p className="m-0">Drag and drop files to here to upload.</p>)
    }

    const onBeforeSelectFUHandler = async (e) => {
        if (e.files.length == 0 && clientJulo) {
            await TriggerJuloEvent(5);
        }
        setIsFilesSelected(true)
        if (isMobileScreen) {
            const element = document.getElementsByClassName('p-fileupload-content')[0];
            if (element) {
                element.style.display = 'block';
            }
        }
    }

    const [activeIndex, setActiveIndex] = useState(null);

    const formRender = () => {
        return (
            <>
                {clientJulo ? (<style>
                    {`
                    .p-button{
                        background-color: #00ACF0 !important;
                        border-color: #00ACF0 !important;
                    }

                    .p-button:hover{
                        background-color: #0089C0 !important;    
                    }

                    .julo-btn{
                        background-color: #00ACF0 !important;
                        border-color: #00ACF0 !important;
                    }

                    .julo-btn:hover{
                        background-color: #0089C0 !important;
                    }

                    .p-fileupload-buttonbar {
                        border-radius: 3px !important;
                        background: rgba(154, 226, 255, 0.14) !important;
                        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1) !important;
                        backdrop-filter: blur(2.7px) !important;
                        -webkit-backdrop-filter: blur(2.7px) !important;
                        border: 1px solid rgba(154, 226, 255, 0.21) !important;
                    }

                    .p-button-danger{
                        background: rgba(154, 226, 255, 0.14) !important;
                        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1) !important;
                        backdrop-filter: blur(2.7px) !important;
                        -webkit-backdrop-filter: blur(2.7px) !important;
                        border: 1px solid rgba(154, 226, 255, 0.21) !important;
                    }

                    .p-highlight{
                        background: rgba(154, 226, 255, 0.14) !important;
                        backdrop-filter: blur(2.7px) !important;
                        -webkit-backdrop-filter: blur(2.7px) !important;               
                    }

                    .p-accordion-header-link{
                        background: rgba(154, 226, 255, 0.14) !important;
                        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1) !important;
                        backdrop-filter: blur(2.7px) !important;
                        -webkit-backdrop-filter: blur(2.7px) !important;
                    }

                    `}
                </style>) : null}

                {isMobileScreen ? (<style>
                    {`
                        .p-fileupload-content{
                            display: none;
                        }
                    `}
                </style>) : null}


                {clientJulo && !isMobileScreen && (<>
                    <div className="card" style={{ "backgroundColor": "transparent", "border": "0px solid transparent" }}>
                        <p className="m-1"><b>Note: </b></p>
                        <p className="m-1 mb-0"><span style={{ fontWeight: "500" }} className='m-0'>1.</span> {t('selectPartnerPage.Banking.note.Julo')}</p>
                        <p className="m-1 mt-0"><span style={{ fontWeight: "500" }} className='m-0'>{t('selectPartnerPage.Banking.note.example_title')}:</span> {t('selectPartnerPage.Banking.note.example')}.</p>
                        <p className="m-1"><span style={{ fontWeight: "500" }} className='m-0'>2.</span> {t('selectPartnerPage.Banking.note.1')}.</p>
                        <p className="m-1"><span style={{ fontWeight: "500" }} className='m-0'>3.</span> {t('selectPartnerPage.Banking.note.2')}.</p>
                        <p className="m-1"><span style={{ fontWeight: "500" }} className='m-0'>4.</span> {t('selectPartnerPage.Banking.note.4')}.</p>
                    </div>
                </>)}

                {clientJulo && isMobileScreen && (<>
                    <div className="card" style={{ "backgroundColor": "transparent", "border": "0px solid transparent" }}>
                        <p className="m-1"><b>Note: </b></p>
                        <p className="m-1 mb-0" style={{ fontSize: "small" }}><span style={{ fontWeight: "500", fontSize: "small" }} className='m-0'>1.</span> {t('selectPartnerPage.Banking.note.Julo')}</p>
                        <p className="m-1 mt-0" style={{ fontSize: "small" }}><span style={{ fontWeight: "500", fontSize: "small" }} className='m-0'>{t('selectPartnerPage.Banking.note.example_title')}:</span> {t('selectPartnerPage.Banking.note.example')}.</p>
                        <p className="m-1" style={{ fontSize: "small" }}><span style={{ fontWeight: "500", fontSize: "small" }} className='m-0'>2.</span> {t('selectPartnerPage.Banking.note.1')}.</p>
                        <p className="m-1" style={{ fontSize: "small" }}><span style={{ fontWeight: "500", fontSize: "small" }} className='m-0'>3.</span> {t('selectPartnerPage.Banking.note.2')}.</p>
                        <p className="m-1" style={{ fontSize: "small" }}><span style={{ fontWeight: "500", fontSize: "small" }} className='m-0'>4.</span> {t('selectPartnerPage.Banking.note.4')}.</p>
                    </div>
                </>)}

                {clientJulo && (
                    <Accordion activeIndex={activeIndex} className='mt-3' onTabChange={async (e) => {
                        const newIndex = e.index;
                        setActiveIndex(newIndex);
                        if (newIndex === 0) { if (clientJulo) { await TriggerJuloEvent(2) } }
                    }}>
                        <AccordionTab header={t('selectPartnerPage.Banking.sample')} >
                            <div className="card" style={{ "backgroundColor": "transparent", "border": "0px solid transparent" }}>
                                <img src={juloImg} style={{ width: "100%", height: "auto" }} />
                            </div>
                        </AccordionTab>
                    </Accordion>
                )}

                <div className="info-p info-p-n">
                    <div className="info-inner-p" ></div>
                    <div style={{ textAlign: 'left', marginTop: '1.5rem' }}>
                        <label className="scl">File Type</label>
                        <div className="input-p">
                            <Dropdown
                                value={selectedUploadType}

                                valueTemplate={selectedFileTemplate}

                                onChange={(e) => {
                                    clean();
                                    setSelectedUploadType(e.value);
                                }}

                                options={uploadOptions}
                                optionLabel="option"

                                placeholder="Select File Type"
                                style={{ width: 'inherit' }}
                                disabled={isAllowSubmit}
                            />
                        </div>
                    </div>

                    {selectedUploadType && selectedUploadType.show == "Image" ? (<div style={{ textAlign: 'left', paddingTop: '30px' }}>
                        <label className="scl">Bank Name</label>
                        <div className="input-p">

                            <Dropdown
                                value={selectedBank}

                                // valueTemplate={selectedFileTemplate}

                                onChange={(e) => { setSelectedBank(e.value); }}

                                onShow={async () => { if (clientJulo) { await TriggerJuloEvent(9); } }}

                                options={isHITLEnabled ? banksWithHITL : banksWithNoHITL}
                                optionLabel="name"

                                placeholder="Select Bank"
                                style={{ width: 'inherit' }}
                                disabled={isAllowSubmit}
                            />

                        </div>
                    </div>) : null}


                    {selectedUploadType && selectedUploadType.show == "PDF" ? (<div style={{ textAlign: 'left', paddingTop: '30px' }}>
                        <label className="scl">{t('selectPartnerPage.Banking.passTitle')}</label>
                        <div className="input-p">
                            <input
                                style={{
                                    fontSize: '.7rem'
                                }}
                                className="form-control"
                                value={password}
                                disabled={isAllowSubmit}
                                onBlur={handleInputBlur}
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder={t('selectPartnerPage.Banking.password')}
                                autoComplete="off"
                                type="password"
                                id="password"
                                name="password"
                            />

                        </div>
                    </div>) : null}

                    <div className="form-p" style={!clientJulo ? { "paddingBottom": "6rem" } : {}}>

                        <div className="card" style={{ backgroundColor: 'transparent', border: '0px solid transparent' }}>
                            {!(selectedUploadType && selectedUploadType.option == 'PDF (For Uploading Single / Multiple Bank Statements)' ? false
                                : selectedBank == null ? true : false) && (
                                    <FileUpload

                                        cancelLabel={t('form.cancel')}
                                        uploadLabel={t('form.upload')}
                                        chooseLabel={t('form.choose')}
                                        chooseOptions={selectedUploadType && selectedUploadType.show == "PDF" ? { icon: 'pi pi-file-pdf' } : selectedUploadType && selectedUploadType.show == "Image" ? { icon: 'pi pi-image' } : { icon: 'pi pi-plus' }}
                                        ref={fileUploadRef}
                                        disabled={
                                            (isAllowSubmit) || (selectedUploadType &&
                                                selectedUploadType.option == 'PDF (For Uploading Single / Multiple Bank Statements)' ? false : selectedBank == null ? true : false)
                                        }
                                        onBeforeSelect={onBeforeSelectFUHandler}

                                        // onBeforeDrop={(e) => {console.log(e.files.length)}}

                                        onSelect={onSelectFUHandler}

                                        onRemove={onRemoveFUHandler}

                                        onClear={onClearFUHandler}
                                        customUpload={true} uploadHandler={customUploadDocuments}
                                        name="files"
                                        onBeforeUpload={onBeforeUploadFUHandler}
                                        url='/api/upload' multiple
                                        accept={selectedUploadType && selectedUploadType.show == "PDF" ? ".pdf" : "image/*"}
                                        maxFileSize={10000000}
                                        invalidFileSizeMessageDetail='File size should be < 10 MB'

                                        emptyTemplate={
                                            emptyTemplateFUHandler
                                        }

                                    />)}
                        </div>

                        <br />

                        {!clientJulo && (<>
                            <div className="card" style={{ "backgroundColor": "transparent", "border": "0px solid transparent" }}>
                                <p className="m-0">Note: </p>
                                <p className="m-0">1. {t('selectPartnerPage.Banking.note.1')}</p>
                                <p className="m-0">2. {t('selectPartnerPage.Banking.note.2')}</p>
                                <p className="m-0">3. {t('selectPartnerPage.Banking.note.3')}</p>
                            </div>
                        </>)}

                    </div>


                </div>
            </>
        )
    }

    const items = [
        {
            label: 'Mode 1',
            icon: 'pi pi-desktop',
            command: () => {
                setMode(1)
            }
        },
        {
            label: 'Mode 2',
            icon: 'pi pi-desktop',
            command: () => {
                setMode(0)
            }
        }
    ];

    return (
        <>
            {clientJulo && showSuccessDialog ? (<style>
                {`
                .p-dialog .p-dialog-header .p-dialog-header-icon:last-child {
                    text-align-last: center !important;
                }
                .p-dialog-header{
                    background: rgb(188 236 255) !important;
                }
                `}
            </style>)
                : (null)}

            {isDataLoading && <FullPageLoader />}
            <Toast ref={toast} />
            {/* <SpeedDial showIcon="pi pi-cog" model={items} direction="left" style={{ top: 'calc(50% - 2rem)', right: 2, top: 2 }} /> */}
            <Dialog header={ModelHeaderContent} footer={ModelFooterContent}
                modal={false} visible={isDialogVisible} className='pw-dialog'
                position="top" style={{ width: '40vw', top: '6vh', border: '1px solid #ccc', borderRadius: '5px', boxShadow: 'rgb(0 0 0 / 38%) 0px 1rem 1rem' }}
                draggable={false} resizable={false} closeOnEscape={false}>
                <div style={{ textAlign: 'left', paddingTop: '30px' }}>
                    <label className="scl" style={{ width: '100%' }}><b>{t('selectPartnerPage.Banking.unRecognisedStatement.message')}</b></label>
                </div>

                {falseStatements && falseStatements.map((statement, index) => {
                    return (
                        <div style={{ display: 'flex', paddingTop: '15px', alignItems: 'center' }} key={index}>
                            <label className="scl">{index + 1}) {statement.name}</label>
                            <div className="input-p">
                                <Dropdown

                                    value='-'

                                    valueTemplate={
                                        () => {
                                            if (statement.bank == '') {
                                                return "Select Bank"
                                            } else {
                                                return statement.bank
                                            }
                                        }
                                    }

                                    onChange={(e) => {
                                        let temp = falseStatements;
                                        temp[index].bank = e.value.option;
                                        setFalseStatements(temp);
                                        observeAllBanksNamed();
                                    }}

                                    options={isHITLEnabled ? banksWithHITL : banksWithNoHITL}
                                    optionLabel="name"

                                    placeholder="Select Bank"
                                    // disabled={isFilesSelected} 

                                    style={{ float: 'right', width: '10rem' }}
                                />
                            </div>
                        </div>
                    )
                })}


            </Dialog>


            <Dialog header={t('selectPartnerPage.Banking.modal.title')} footer={JuloModelFooterContent}
                modal={false} visible={(clientJulo && showSuccessDialog)} className='pw-dialog'
                onHide={() => {
                    setShowSuccessDialog(false);
                    setIsDataLoading(true);
                    window.location.href = 'https://www.julo.co.id/blog/data-kamu-aman-dengan-JULO?in_app=true';
                }}
                position="top" style={{ width: '40vw', top: '6vh', border: '1px solid #ccc', borderRadius: '5px', boxShadow: 'rgb(0 0 0 / 38%) 0px 1rem 1rem' }}
                draggable={false} resizable={false} closeOnEscape={false}>
                <div style={{ textAlign: 'left', paddingTop: '30px' }}>
                    <p className="" style={{ width: '100%' }}>{t('selectPartnerPage.Banking.modal.message')}.</p>
                </div>
            </Dialog>
            <div className="partner-page-form-n">
                <div>
                    <h3 className="title-p title-p-p1 title-p-n">
                        {t('selectPartnerPage.Banking.title')}
                        <svg xmlns="http://www.w3.org/2000/svg" onMouseOver={(e) => { e.currentTarget.nextElementSibling.style.display = 'inline-block'; e.currentTarget.nextElementSibling.style.top = e.clientY - 0 + 'px'; e.currentTarget.nextElementSibling.style.left = e.clientX + 10 + 'px'; }} onMouseOut={(e) => e.currentTarget.nextElementSibling.style.display = 'none'} width="13" height="13" fill="black" className='partner-info-icon' viewBox="0 0 16 16">
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                            <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                        </svg>
                        <div className='partner-info-icon-message'>
                            {
                                t('selectPartnerPage.Banking.info').split('\n').map((item, i) => {
                                    return <p key={i} style={{ marginBottom: '0rem', fontSize: '.9rem' }}>{item}</p>;
                                })
                            }
                        </div>
                    </h3>
                    {/* <p><b>{mode == 0 ? " DEMO 1 " : " DEMO 2 "}</b></p> */}
                    {formRender()}
                </div>

                <div className="btn-div-p-n">
                    {!clientJulo && (<div className="back-btn-pp back-btn-pp-n btn nav-btn-pwc next-btn" style={isMobileScreen ? {} : { display: 'none' }} onClick={back}>
                        {t('form.back')}
                    </div>)}
                    {!clientJulo && isMobileScreen && (<BrandBadge />)}
                    {/* <div className="buttons-bottom-div">
                        <button type="submit" className="btn nav-btn-pwc next-btn btn-n btn-next-partner-n"
                            disabled={isAllowSubmit ? false : true}
                            onClick={() => {
                                onFormSuccess(true);
                            }}
                        >{t('form.submit')} 
                        </button>
                    </div> */}
                </div>
                {isMobileScreen && clientJulo && (<BrandBadge option={"footer"} />)}
            </div>
        </>
    );
};

export default MultiBanking;
